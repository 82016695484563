import React from 'react';
import greenCheckIcon from '../assets/images/Green-Checkmark-icon.svg';
import yellowCheckIcon from '../assets/images/O-check-mark.svg';

const GreencheckIcon = () => (
    <img className='green-check-icon' src={greenCheckIcon} alt="" loading='lazy' />
);

const YellowcheckIcon = () => (
    <img className='green-check-icon' src={yellowCheckIcon} alt="" loading='lazy' />
);

export const financeAccounting = [
    { id: 1, name: "Finance & Accounting", start: <YellowcheckIcon />, professional: <YellowcheckIcon />, business: <><YellowcheckIcon /><GreencheckIcon /></>, enterprise: <GreencheckIcon />, both: true, bold: true},
    { id: 2, name: "Met de volgende functies:", start: "", professional: "", business: "", enterprise: "", bold: true},
    { id: 3, name: "Boekhoudkoppelingen", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Exact Online in- en verkoop journaalposten", "Exact Online uitgestelde omzet", "Exact Online verwachten kosten tussenrekening inkoop", "Exact Online nog te ontvangen inkoopfacturen", "Exact Online inlezen betalingen in TravelSpirit", "Exact Online kostenplaatsen", "Exact Online kostendragers"]},
    { id: 4, name: "Crediteurenbeheer", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Ontvangen en registereren van facturen", "Organiseren en uitvoeren van betalingen", "Overzicht budget- en cashflowbeheer"]},
    { id: 5, name: "Debiteurenbeheer", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Verzenden van facturen", "Monitoren van openstaande facturen", "Betaallink versturen"]},
    { id: 6, name: "Paymentlinks", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Koppeling met partners: Mollie, Ingenico & Buckaroo", "Toevoegen betaallink in e-mail (aanbetaling, restantbetaling of totale betaling)", "Toevoegen meerdere links in e-mail", "Toevoegen betaallinks met willekeurig bedrag", "Mogelijkheid tot het kiezen welke betaalmethoden worden aangeboden"]},
    { id: 7, name: "Reisbureauregeling", start: "", professional: "", business: "", enterprise: "", extrainfo: ["BTW over de omzet en marge", "On the fly uitrekenen BTW over de marge", "Tonen BTW totalen op de factuur", "In bepaalde gevallen niet tonen BTW", "BTW regeling d.m.v. kostenplaatsen of grootboeknummers"]},
    { id: 8, name: "eFacturen", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Toevoegen eFactuur XML aan e-mail met PDF factuur", "UBL 2.1"]}
];