import React from 'react';
import greenCheckIcon from '../assets/images/Green-Checkmark-icon.svg';
import yellowCheckIcon from '../assets/images/O-check-mark.svg';
import redCheckIcon from '../assets/images/X-check-mark.svg';

const GreencheckIcon = () => (
    <img className='green-check-icon' src={greenCheckIcon} alt="" loading='lazy' />
);

const YellowcheckIcon = () => (
    <img className='green-check-icon' src={yellowCheckIcon} alt="" loading='lazy' />
);

const RedcheckIcon = () => (
    <img className='green-check-icon' src={redCheckIcon} alt="" loading='lazy' />
);

export const website = [
    { id: 1, name: "Website (SpoE)", start: <RedcheckIcon />, professional: <YellowcheckIcon />, business: <><YellowcheckIcon /><GreencheckIcon /></>, enterprise: <GreencheckIcon />, both: true, bold: true},
    { id: 2, name: "Met de volgende functies:", start: "", professional: "", business: "", enterprise: "", bold: true},
    { id: 3, name: "Single Point of Entry", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Eén centrale plek", "Consistentie communicatie", "Integratie van systemen"]},
    { id: 4, name: "Producten & pakketten content", start: "", professional: "", business: "", enterprise: ""},
    { id: 5, name: "Online prijzen", start: "", professional: "", business: "", enterprise: ""},
    { id: 6, name: "Boekbare website", start: "", professional: "", business: "", enterprise: ""},
    { id: 7, name: "Online boeken", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Single Point of Entry (website)", "Koppeling naar tussendatabase website"]}
];