import React from 'react';
import greenCheckIcon from '../assets/images/Green-Checkmark-icon.svg';
import yellowCheckIcon from '../assets/images/O-check-mark.svg';

const GreencheckIcon = () => (
    <img className='green-check-icon' src={greenCheckIcon} alt="" loading='lazy' />
);

const YellowcheckIcon = () => (
    <img className='green-check-icon' src={yellowCheckIcon} alt="" loading='lazy' />
);

export const visualtourbuilder = [
    { id: 1, name: "Visual Tour Builder", start: <YellowcheckIcon />, professional: <YellowcheckIcon />, business: <><YellowcheckIcon /><GreencheckIcon /></>, enterprise: <GreencheckIcon />, both: true, bold: true},
    { id: 2, name: "Met de volgende functies:", start: "", professional: "", business: "", enterprise: "", bold: true},
    { id: 3, name: "Online offertes op maat", start: "", professional: "", business: "", enterprise: "", extrainfo: ["Interactieve website of PDF", "Volledig in eigen huisstijl", "Real-time wijzigingen doorvoeren"]},
    { id: 4, name: "Calculeren", start: "", professional: "", business: "", enterprise: ""},
    { id: 5, name: "Drag & drop offertes maken", start: "", professional: "", business: "", enterprise: ""}
];